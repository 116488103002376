import React, { useState } from 'react'
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Col, InputGroup } from "react-bootstrap";
import { numberValidation, monthValidation } from './_Shared/Validation';
import { Alert } from "reactstrap";
function Login(props) {
    localStorage.setItem('myData', 'false');
    const [employee, setemployee] = useState({ Email: '', Password: '' });
    const [error, setError] = useState("");
    //const apiUrl = "https://localhost:44339/login";
    const apiUrl = "https://demoslideguide.azurewebsites.net/login";
    const header = {
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "X-Requested-With, Content-Type, Accept, X-Token",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*"
    }
    const Login = (e) => {
        e.preventDefault();
        axios.get(apiUrl, { params: { email: employee.Email, pwd: employee.Password } }, {

            headers: header
        })
            .then((result) => {
                console.log(result);
                if (result.data == 'Member has access') {
                    localStorage.setItem('myData', 'true');
                    props.history.push('/home')
                }
                else if (result.data == 'Not an active member') {
                    setError(<Alert className="text-center fade alert alert-danger show">
                        This content is for NADA and ATD Members. For membership details or assistance please contact us at 800.557.6232</Alert>);
                }
                else {
                    //console.log("Check Password");
                    setError(<Alert className="text-center fade alert alert-danger show">
                        The email or password is incorrect. Please check and try again. Need help? Contact us at 800.557.6232</Alert>);
                }
            })
    };

    const validationSchema = Yup.object({
        email: numberValidation,
        password: numberValidation
    });

    const onChange = (e) => {
        e.persist();
        setemployee({ ...employee, [e.target.name]: e.target.value });
    }
    return (

        <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron">
                            <div className="col-sm-10 mx-auto">
                                <h2 className="text-center">NADA Member Login</h2>
                                {error && <span className="error">{error}</span>}

                                <Form onSubmit={Login}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="netProfit">
                                            <Form.Label>Email</Form.Label>
                                            <InputGroup className="mmb-2"><InputGroup.Prepend></InputGroup.Prepend>
                                                <Form.Control size="lg" type="email" name="Email" placeholder="Email" isInvalid={!!errors.setEmail}
                                                    value={employee.Email} onChange={onChange}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="sales">
                                            <Form.Label>Password</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend></InputGroup.Prepend>
                                                <Form.Control size="lg" type="password" name="Password" placeholder="Password" isInvalid={!!errors.setPassword}
                                                    value={employee.Password} onChange={onChange}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>

                                    <p className="pb-2">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Login &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

export default Login 