
//import React from 'react';
import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import BackButton from './_Shared/BackButton';
import { useHistory } from "react-router-dom";
import {Accordion, Card, Button, Modal } from 'react-bootstrap'

function Calculations() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleRegister = () => window.open('https://show.nada.org/2022/attendee-registration/', '_blank');
    const history = useHistory();
    if (localStorage.getItem('myData') != 'true') {
        history.push(
            {
                pathname: `/`
            })
    }

    return (

        <div className="container">
            <main role="main">
                <div className="jumbotron ">
                    <div className="col-sm-10 mx-auto">
                       <h2 className="text-center">
                            <BackButton />KPIs with Guides and Comparisons</h2>

                        <img src="/images/NADA_Show.png" alt="NADA Logo" className="img-fluid mx-auto d-block" onClick={handleRegister} height="90px" />
                        <Button className="list-group-item list-group-item-action register-list text-center" onClick={handleRegister}>Click here to Register</Button>
                        <Accordion>
                            {/*----DEMO GUIDES----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item-demo" eventKey="0">
                                    <h5>Demo Guides &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="list-group">

                                            <Link to="/netprofitreturnonsales"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Profit Return on Sales</button></Link>
                                            <Link to="/retailunitssoldpersalespeople"><button type="button" className="list-group-item list-group-item-action calculation-list">Retail Unit Sold per Salespeople</button></Link>
                                            <Link to="/techsupport"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech-to-Support Personnel Ratio</button></Link>
                                            <Link to="/domesticpartssalesperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Domestic Parts Sales Per Parts Employee</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        {/*----DEALERSHIP VITALS----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="1">
                                    <h5>Dealership Vitals &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="list-group">
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list calculation-list">Asset Utilization</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Cash Days Supply</button>
                                            <button type="button" onClick={handleShow}className="list-group-item list-group-item-action calculation-list">Cash in Bank</button>
                                            <button type="button" onClick={handleShow}className="list-group-item list-group-item-action calculation-list">Current Ratio (Current Assets to Current Liabilities)</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Debt to Equity</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Frozen Capital</button>
                                            <button type="button" onClick={handleShow}className="list-group-item list-group-item-action calculation-list">Inventory Trust Position</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">LIFO (Last In, First Out)</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Net Profit Return on Sales</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Net Profit Return on Assets</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Net Worth</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">New Vehicle Department Breakeven Point</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Parts Obsolescence</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Return On Equity</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Service Department Proficiency</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Service Sales Potential and Retention</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Total Absorption</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Fixed Absorption</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Units In Operation</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Used Vehicles Days Supply in Dollars</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Used Vehicles Days Supply in Units</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Variable Expense</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Working Capital</button>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----NEW AND USED VEHICLE DEPARTMENT----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="2">
                                    <h5>New and Used Vehicle Department &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="list-group">
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Retail Unit Sold per Salespeople</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Used to New Retail Ratio</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">New Inventory Days Supply</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Used Inventory Days Supply</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Used Units Over 60 Days</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Reconditioning Turnaround Time</button>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----SERVICE----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="3">
                                    <h5>Service &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="list-group">
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Tech-to-Support Personnel Ratio</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Tech Proficiency</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Tech Productivity</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Service Labor Hours per RO</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Stall Utilization</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Customer Labor Gross Retention</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Warranty Labor Gross Retention</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Internal Labor Gross Retention</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Total Service Department Gross % Sales</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Service & Parts Receivables 0-30 Days</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Service & Parts Receivables 31-60 Days</button>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----PARTS----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="4">
                                    <h5>Parts &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <div className="list-group">
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Domestic Parts Sales Per Parts Employee</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Import Non-Luxury Parts Sales Per Parts Employee</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Luxury Parts Sales Per Parts Employee</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Domestic Parts Gross Per Parts Employee</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Import Non-Luxury Parts Gross Per Parts Employee</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Luxury Parts Gross per Parts Employee</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Parts To Labor Ratio</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Parts Turnover</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Inventory Profile</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Parts Months Supply</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Fill Rate First TIme</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Fill Rate Same Day Pick-up</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Active/Normal Status</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Total Parts Department Gross Percent Sales</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Warranty Receivables</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Body Shop Technician Efficiency</button>         
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Total Body Shop Gross % Sales</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Body Shop Labor Gross % Sales</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Parts Body Shop RO Gross % Sales</button>
                                            <button type="button" onClick={handleShow} className="list-group-item list-group-item-action calculation-list">Body Shop Paint & Materials Gross % Sales</button>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>NADA 2020 Definitions & Guides</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src="/images/NADA_Show.png" alt="NADA Logo" className="img-fluid" onClick={handleRegister} height="46px" />
                    For all the Guides, please register for the 2022 NADA Show</Modal.Body>
                            <Modal.Footer>

                        <Button className="list-group-item list-group-item-action calculation-list text-center" onClick={handleRegister}>
                                   Click here to Register
          </Button>
                            </Modal.Footer>
                        </Modal>
            </main>
        </div>
    );
}


export default Calculations;