import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function TechSupport() {
    const [technicians, setTechnicians] = useState(0);
    const [employees, setEmployees] = useState(0);   
    const [total, setTotal] = useState(0);
    const history = useHistory();
    if (localStorage.getItem('myData') != 'true') {
        history.push(
            {
                pathname: `/`
            })
    }

    useEffect(() => {
        setTotal (parseInt(technicians) / parseInt(employees));
    }, [technicians, employees])

    function clickResult() {
        history.push(
            {
                pathname: `/techsupportresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        technicians: numberValidation,
        employees: numberValidation
    });

    const def = <span># of Technicians &#247; # of Non-Technician Employees in Service Dept</span>;

    return (
        <Formik
            initialValues={{ technicians: "", employees: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Tech-to-Support Personnel Ratio</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="technicians">
                                            <Form.Label>Number of Service Technicians</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="technicians" value={values.technicians} isInvalid={!!errors.technicians}
                                                onChange={e => { handleChange(e); setTechnicians(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.technicians}</Form.Control.Feedback>
                                                </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="employees">
                                                <Form.Label>Number of Service Employees excluding Technicians</Form.Label>
                                                <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="employees" value={values.employees} isInvalid={!!errors.employees}
                                                onChange={e => { handleChange(e); setEmployees(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.employees}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                      
                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function TechSupportResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Tech-to-Support Personnel Ratio";
    const total = urlParams.get('total') + " : 1";
    const ratio = "1.5 : 1";

    //const cond = parseFloat(total) == 1.5;

    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">{title}</h2>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="card h-100 text-white mb-3 answer-card">
                                <div className="answer-card-grey">
                                    <div className="card-header card-results text-center">Your Data</div>
                                    <div className="card-body">
                                        <h1 className="card-title text-center">{total}</h1>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="card h-100 text-white bg-dark mb-3 ratio-card">
                                <div className="card-header card-results text-center">NADA Guide</div>
                                <div className="card-body">
                                    <h1 className="card-title text-center"> {ratio} </h1>
                                    <p className="card-text text-center">Minimum Ratio</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {
    TechSupport,
    TechSupportResult
}