import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './components/Home';
import NavMenu from './components/_Shared/NavMenu';
import Calculations from './components/Calculations';
import Login from './components/Login';

//Dealership Vitals
import { NetProfitReturnOnSales, NetProfitReturnOnSalesResult } from './components/DealershipVitals/NetProfitReturnOnSales';

//NEW AND USED VEHICLE DEPARTMENT
import { RetailUnitSoldPerSalespeople, RetailUnitSoldPerSalespeopleResult } from './components/NewandUsedVehicleDepartment/RetailUnitSoldPerSalespeople';

//SERVICE
import { TechSupport, TechSupportResult } from './components/Service/TechSupport';

//PARTS
import { DomesticPartsSalesPerPartsEmployee, DomesticPartsSalesPerPartsEmployeeResult } from './components/Parts/DomesticPartsSalesPerPartsEmployee';

function App() {
    return (
        <Router>
            <NavMenu/>
            <Switch>
                <Route exact path='/' component={Login} />
                <Route path='/calculations' component={Calculations} />
                <Route path='/home' component={Home} />

                {/*----DEALERSHIPS VITALS----*/}
                <Route path='/netprofitreturnonsales' component={NetProfitReturnOnSales} />
                <Route path='/netprofitreturnonsalesresult?total=:total' component={NetProfitReturnOnSalesResult} />
                
                {/*----SALES----*/}
                <Route path='/retailunitssoldpersalespeople' component={RetailUnitSoldPerSalespeople} />
                <Route path='/retailunitssoldpersalespeopleresult?total=:total' component={RetailUnitSoldPerSalespeopleResult} />

                {/*----SERVICE----*/}
                <Route path='/techsupport' component={TechSupport} />
                <Route path='/techsupportresult?total=:total' component={TechSupportResult} />
               
                {/*----PARTS----*/}
                <Route path='/domesticpartssalesperpartsemployee' component={DomesticPartsSalesPerPartsEmployee} />
                <Route path='/domesticpartssalesperpartsemployeeresult?total=:total' component={DomesticPartsSalesPerPartsEmployeeResult} />

            </Switch>

        </Router>
    );
}

export default withRouter(App);
