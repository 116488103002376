import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {Button, Modal } from 'react-bootstrap'

function Home() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleRegister = () => window.open('https://show.nada.org/2022/attendee-registration/', '_blank');
    const history = useHistory();
    if (localStorage.getItem('myData') != 'true') {
        history.push(
            {
                pathname: `/`
            })
    }
    return (

        <div className="container">
            <main role="main">
                <div className="jumbotron">
                    <div className="col-sm-10 mx-auto text-center pt-5 pb-5">
                        <h1 className="pt-5 pb-5">NADA 2022 Definitions & Guides</h1>
                        <Link to="/calculations">
                            <p className="pb-5">
                                <button className="btn btn-primary btn-lg btn-block">View Guides and Compare Your Data &raquo;</button>
                            </p>
                        </Link>
                            <p className="def">
                            New this year to NADA Show is the NADA Guide App, which allows you to enter specific financial statement 
                            data and see how your dealership performance compares. The Guide App analyzes key performance metrics such 
                            as Asset Utilization, Return on Sales, New & Used Salespeople Productivity and Technician Proficiency. 
                                The full App will be made available to all NADA Show registrants. <a href="https://show.nada.org/2022/attendee-registration/" target="_blank">Register for NADA Show</a> and look for 
                                it under the Expo tab in the Academy and 20 Group sections of the virtual NADA Pavilion. ATD Slide Guide App coming soon.
                            </p>

                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                        
                                <button type="button" onClick={handleShow} className="btn pdf-button btn-md test">NADA 2022 Definitions and Guide PDF <i className="fa fa-download"></i>
                                </button>

                                </div>
                            <div className="col-md-6 col-sm-12">

                                <button type="button" onClick={handleShow} className="btn pdf-button btn-md right">ATD 2022 Definitions and Guide PDF <i className="fa fa-download"></i>
                                </button>
                                </div>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>NADA 2020 Definitions & Guides</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src="/images/NADA_Show.png" alt="NADA Logo" className="img-fluid" onClick={handleRegister} height="46px" />
                    For all the Guides, please register for the 2022 NADA Show</Modal.Body>
                    <Modal.Footer>

                        <Button className="list-group-item list-group-item-action calculation-list text-center" onClick={handleRegister}>
                            Click here to Register
          </Button>
                    </Modal.Footer>
                </Modal>
            </main>
        </div>
    );
}


export default Home;