import React, { Component, useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { BsPower } from "react-icons/bs";
import { Link } from 'react-router-dom';
import '../../App.css';

const NavMenu = () => {

    //const [user, setUser] = useState({});
    //useEffect(() => {
    //    { /*
    //    setInterval was used in order to refresh the page constantly
    //in order to have the "logout" button show immediately in place of
    //"login", as soon as user logs out.
    //*/}
    //    setInterval(() => {
    //        const userString = localStorage.getItem('myData');
    //        const user = JSON.parse(userString);
    //        setUser(user);
    //    }, [])
    //}, 50);

    //const logout = () => {
    //    return localStorage.removeItem("user");
    //}
        return (
            <Navbar className="navbar navbar-dark bg-dark-top">
                <Link to='/home' className='navbar-brand'>
                    <img src="/images/NADAMark_white.png" alt="NADA Logo" height="46px" />
                </Link>

                <NavLink tag={Link} className="nav-link float-right" to="/"><BsPower size="1.5rem" color="white"/></NavLink>
                {/*<NavbarToggler onClick={this.toggleNavbar} className="navbar-toggler collapsed"><span className="navbar-toggler-icon" /></NavbarToggler>
            <Collapse className="navbar-collapse collapsed" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav mr-auto">
                <NavItem>
                        <NavLink tag={Link} className="nav-link float-right" to="/">Home</NavLink>
                        //<br/>
                        <NavLink tag={Link} className="nav-link float-right" to="/Login">Logout</NavLink>
                </NavItem>
              </ul>
            </Collapse>*/}
            </Navbar>
        )
}
export default NavMenu;
